import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/agreements": [6,[2]],
		"/(app)/agreements/edit/[id]": [13,[2]],
		"/(app)/agreements/new": [14,[2]],
		"/(app)/agreements/[id=integer]": [7,[2]],
		"/(app)/agreements/[id=integer]/change-history": [9,[2]],
		"/(app)/agreements/[id=integer]/request-for-changes": [10,[2,3]],
		"/(app)/agreements/[id=integer]/request-for-changes/form": [11,[2,3]],
		"/(app)/agreements/[id=integer]/request-for-changes/form/preview": [12,[2,3]],
		"/(app)/agreements/[id=integer]/(success)/request-for-changes/form/success": [8,[2]],
		"/(app)/bills": [15,[2]],
		"/(app)/bills/[id]": [16,[2]],
		"/(app)/bills/[id]/wip": [17,[2]],
		"/(app)/candidates/[id=integer]x[id2=integer]": [18,[2]],
		"/(app)/changes-webhook": [21,[2]],
		"/(app)/changes": [19,[2]],
		"/(app)/changes/[id=integer]": [20,[2]],
		"/(app)/client-vas": [22,[2]],
		"/(app)/clients": [23,[2]],
		"/(app)/clients/edit/[id]": [25,[2]],
		"/(app)/clients/new": [26,[2]],
		"/(app)/clients/[id]": [24,[2]],
		"/(app)/finance-bills": [27,[2]],
		"/(app)/finance-invoices": [28,[2]],
		"/forbidden": [53],
		"/(app)/invoice-claims": [29,[2]],
		"/(app)/invoices": [30,[2]],
		"/(app)/invoices/[id]": [31,[2]],
		"/(app)/invoices/[id]/wip": [32,[2]],
		"/(app)/kanban": [33,[2]],
		"/(app)/login": [34,[2,4]],
		"/(app)/login/forgot-password": [35,[2,4]],
		"/(app)/login/forgot-password/success": [36,[2,4]],
		"/(app)/login/reset-password": [37,[2,4]],
		"/public/print-invoice/[invoice_id]": [~54],
		"/(app)/reports": [38,[2]],
		"/(app)/select-client": [39,[2]],
		"/(app)/settings": [40,[2]],
		"/(app)/sync-logs": [41,[2]],
		"/(app)/tracker": [42,[2]],
		"/(app)/tracker/[id=integer]": [43,[2]],
		"/(app)/unauthorized": [44,[2]],
		"/(app)/users": [45,[2]],
		"/(app)/users/edit/[id=integer]": [47,[2]],
		"/(app)/users/new": [48,[2]],
		"/(app)/users/[id=integer]": [46,[2]],
		"/(app)/vas": [49,[2]],
		"/(app)/vas/edit/[id]": [51,[2]],
		"/(app)/vas/new": [52,[2]],
		"/(app)/vas/[id]": [50,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';