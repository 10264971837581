import type { HandleClientError } from "@sveltejs/kit";
import { get } from "svelte/store";
import {
	defaultStackParser,
	getCurrentHub,
	makeFetchTransport,
	BrowserClient,
	Dedupe,
	FunctionToString,
	GlobalHandlers,
	HttpContext,
	LinkedErrors,
	TryCatch,
	BrowserTracing,
} from "@sentry/svelte";
import { handleErrorWithSentry } from "@sentry/sveltekit";
import { version } from "$app/environment";
import { authReady, initAuth, type initialDataInterface } from "$lib/auth/index";
import { isClient } from "$lib/auth/isRole";
import { getHomepageFromRole } from "$lib/auth/getHomepageFromRole";
import { AppStore } from "$lib/stores";
import "./app.css"; // preload css

if (
	import.meta.env.VITE_ENVIRONMENT === "production" ||
	import.meta.env.VITE_ENVIRONMENT === "staging"
) {
	const client = new BrowserClient({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_ENVIRONMENT,
		transport: makeFetchTransport,
		tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 0.15,
		stackParser: defaultStackParser,
		release: version,

		// Only the integrations listed here will be used
		integrations: [
			new Dedupe(),
			new FunctionToString(),
			new GlobalHandlers(),
			new HttpContext(),
			new LinkedErrors(),
			new TryCatch(),
			new BrowserTracing(),
		],
	});

	getCurrentHub().bindClient(client);
}

const pathname = window.location.pathname;

function hasUserData(userData: initialDataInterface | null): userData is initialDataInterface {
	return userData !== null;
}
// Wait until auth is ready so we can redirect user if necessary
await authReady();

/*
	on page load redirect if user is not in the page he/she should be
*/
const { user, userRole } = initAuth();
const { currentClient } = AppStore;
const userData = get(user);
const loggedIn = hasUserData(userData);

if (loggedIn && (pathname === "/login" || pathname === "/")) {
	/* 
		logged in, redirect to homepage based on role
	*/
	window.location.href = getHomepageFromRole(userRole, Number(userData.hasura_user_id));
} else if (loggedIn && userData.unauthorizedRoutes?.some((route) => pathname.startsWith(route))) {
	/*
		logged in but unauthorized to access this route, redirect to unauthorized page
	*/
	window.location.href = "/unauthorized";
} else if (loggedIn || pathname.startsWith("/login")) {
	if (isClient(userRole) && get(currentClient) === null && pathname !== "/select-client") {
		/*
			client user logged in without currentClient set in store, redirect to select one
		*/
		window.location.href = "/select-client";
	}
} else if (!loggedIn && pathname.startsWith("/login") === false) {
	/*
		neither authenticated nor in login routes, redirect to login page
	*/
	window.location.href = pathname !== "/" ? `/login?redirectTo=${pathname}` : "/login";
}

const myErrorHandler: HandleClientError = ({ error, event }) => {
	console.error("An error occurred on the client side:", error, event);
};

export const handleError = handleErrorWithSentry(myErrorHandler);
